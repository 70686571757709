<template>
    <div>
        <div class="form-row form-row_grid">
            <div class="form-col">
                <div>
                    <label for="bannerMessage">message</label>
                    <text-input id="bannerMessage" v-model="banner.message" />
                    <span v-if="hasError('banner.message')" class="form-error">{{ showError('banner.message') }}</span>
                </div>
                <div class="mt-4">
                    <label for="buttonText">link text</label>
                    <text-input id="buttonText" v-model="banner.linkText" />
                    <span v-if="hasError('banner.linkText')" class="form-error">{{ showError('banner.linkText') }}</span>
                </div>
                <div class="mt-4">
                    <label>link url</label>
                    <url-input v-model="banner.linkUrl" :website-id="websiteId"></url-input>
                    <span v-if="hasError('banner.linkUrl')" class="form-error">{{ showError('banner.linkUrl') }}</span>
                </div>
                <div class="mt-4">
                    <label>display link as button?</label>
                    <toggle-input v-model="banner.linkIsButton" />
                </div>
            </div>

            <div class="form-col">
                <div>
                    <label>promo is temporary?</label>
                    <toggle-input v-model="banner.isTemporary" />
                </div>
                <div v-if="banner.isTemporary === true || banner.isTemporary === '1'" class="mt-4">
                    <div>
                        <label for="startDate">start date</label>
                        <input type="date" id="startDate" v-model="banner.startDate" class="form-input">
                        <span v-if="hasError('banner.startDate')" class="form-error">{{ showError('banner.startDate') }}</span>
                    </div>
                    <div class="mt-4">
                        <label for="endDate">end date</label>
                        <input type="date" id="endDate" v-model="banner.endDate" class="form-input">
                        <span v-if="hasError('banner.endDate')" class="form-error">{{ showError('banner.endDate') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
import Widget from "@/mixins/Widget";
import ToggleInput from "@/components/ui/ToggleInput";
import UrlInput from "@/components/cms/UrlInput";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import TextInput from '@/components/ui/TextInput';

export default {
    mixins: [ ModalNavigation, Widget ],
    components: { ModalFooter, UrlInput, ToggleInput, TextInput },
    emits: ['save-page-widget'],
    data: () => {
        return {
            isProcessing: false,
            banner: {
                message: null,
                linkText: null,
                linkUrl: null,
                linkIsButton: false,
                isTemporary: false,
                startDate: null,
                endDate: null
            }
        }
    },
    methods: {
        save() {
            if (this.validator.passes()) {
                this.isProcessing = true;
                this.$emit('save-page-widget', Object.assign({}, this.banner));
            }
        },
    },
    mounted() {
        this.setActiveModalTitle('Promo banner');
    },
    validations: {
        'banner.message': 'required',
        'banner.linkText': 'required',
        'banner.linkUrl': 'required',
        'banner.startDate': 'when_present | date | before_or_equal:banner.endDate',
        'banner.endDate': 'when_present | date | after_or_equal:banner.startDate',
    },
    created() {
        this.initValidator();

        if (this.values.length) {
            Object.assign(this.banner, {
                message: this.getValue('message'),
                linkText: this.getValue('linkText'),
                linkUrl: this.getValue('linkUrl'),
                linkIsButton: this.getValue('linkIsButton'),
                isTemporary: this.getValue('isTemporary'),
                startDate: this.getValue('startDate'),
                endDate: this.getValue('endDate'),
            });
        }
    }
}
</script>
